<template>
  <div class="campaign-filter">
    <v-menu
      v-model="input.scope.toggle"
      transition="scroll-y-transition"
      close-delay="500"
      :close-on-content-click="false"
      class="selection-control"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          text
          large
          color="primary"
          class="scope-btn"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon 
            left
            class="mr-3"
          >
            {{ icons.filter }}
          </v-icon>
          <span class="font-weight-bold text-truncate">
            {{ scopeDisplay }}
          </span>
          <v-icon 
            right
            class="ml-1"
          >
            {{ icons.menu }}
          </v-icon>
        </v-btn>
      </template>
      <v-card 
        class="campaign-list scrollable"
        max-height="60vh"
      >
        <v-progress-linear
          :active="loading"
          absolute
          indeterminate
          height="2"
          color="primary"
          class="loading faded"
        />
        
        <v-list
          dense
          class="campaign-selection"
        >
          <v-list-item class="pr-2">
            <v-list-item-icon class="mr-4">
              <v-icon 
                size="20"
                color="primary"
              >
                {{ icons.filter }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title 
                class="text-button primary--text font-weight-medium"
              >
                <span class="primary--text font-weight-bold">
                  {{ campaign==null ? 'Carregando...' : campaign.title }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-0">
              <v-menu
                transition="slide-x-transition"
                max-width="50vw"
                max-height="80vh"
                offset-x
                right
                nudge-top="12"
                open-on-hover
                open-delay="250"
                close-delay="250"
                :close-on-content-click="false"
                class="selection-control"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    icon
                    :color="value ? 'primary' : 'grey'"
                    v-on="on"
                    v-bind="attrs"
                    @click.stop
                  >
                    <v-icon 
                      dense
                    >
                      {{ icons.plan }}
                    </v-icon>
                  </v-btn>
                </template>
                <campaign-outline
                  :campaign="campaign"
                  :loading="!statusSelected.updated"
                  :children="children"
                  :cities="cities"
                  :admin="admin"
                  max-height="80vh"
                  class="plan"
                />
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <v-list-item-group
            v-for="(child, i) in children"
            :key="`child-${i}`"
            :value="children"
            color="grey darken-1"
            multiple
            class="child"
          >
            <v-divider 
              vertical 
              class="path dashed" 
            />
            <v-list-item 
              class="child-item pl-4 pr-2 grey--text text--darken-1"
              @click.stop="selectChild(!groups[child.id], child.id)"
            >
              <v-list-item-icon class="mr-4 d-flex align-center">
                <v-icon
                  size="20"
                  :color="groups[child.id] ? 'primary' : 'grey darken-2'"
                >
                  {{ groups[child.id] ? icons.checked : icons.unchecked }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title 
                  class="text-button selected small font-weight-medium"
                  :class="{ 'primary--text': groups[child.id] }"
                >
                  {{ child.title }}
                  <v-tooltip right small>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-if="(admin&&!child.visible)"
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                      >
                        {{ icons.invisible }}
                      </v-icon>
                    </template>
                    <span>Invisível</span>
                  </v-tooltip>
                  <v-tooltip right small>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-if="(!child.airing)"
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                      >
                        {{ icons.inactive }}
                      </v-icon>
                    </template>
                    <span>Fora do ar</span>
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="my-0">
                <v-menu
                  transition="slide-x-transition"
                  max-width="50vw"
                  max-height="80vh"
                  offset-x
                  right
                  nudge-top="12"
                  open-on-hover
                  open-delay="250"
                  close-delay="250"
                  :close-on-content-click="false"
                  class="selection-control"
                >
                  <template v-slot:activator="{ on, attrs, value }">
                    <v-btn
                      icon
                      :color="value ? 'primary' : 'grey'"
                      v-on="on"
                      v-bind="attrs"
                      @click.stop
                    >
                      <v-icon 
                        dense
                      >
                        {{ icons.plan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <campaign-outline
                    :campaign="campaigns[child.id]"
                    :loading="!status[child.id].updated"
                    :cities="cities"
                    :admin="admin"
                    max-height="80vh"
                    class="plan"
                  />
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-text-field
          v-if="admin&&campaignList.length>5"
          v-model="input.search.value"
          :loading="input.search.loading"
          :disabled="input.search.disabled"
          :error-messages="input.search.messages"
          :prepend-inner-icon="icons.search"
          :label="input.search.label"
          :hint="input.search.hint"
          solo
          flat
          hide-details
          clearable
          name="campaign-search"
          autocomplete="off"
          ref="search"
          @keyup.esc="input.search.value=''"
        />

        <!-- <v-divider v-if="admin&&campaignList.length>5" /> -->

        <v-divider />

        <v-list 
          class="list py-0"
        >
          <!-- <v-subheader 
            v-if="listByStatus.on.length>0"
            class="on text-overline success--text pt-2"
          >
            No ar
          </v-subheader> -->
          <v-list-item-group
            color="primary"
            class="on"
          >
            <template v-for="item in listByStatus.on">
              <v-list-item
                :key="item.value"
                class="pr-2 py-1"
                @click="onScopeUpdate(item.value)"
              >
                <v-list-item-content>
                  <v-list-item-title class="small">
                    {{ item.text }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          v-if="(admin&&!item.visible)"
                          small
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                        >
                          {{ icons.invisible }}
                        </v-icon>
                      </template>
                      <span>Invisível</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          v-if="(admin&&item.paid!=2)"
                          small
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                        >
                          {{ mode[item.paid].icon }}
                        </v-icon>
                      </template>
                      <span>{{ mode[item.paid].text }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <v-menu
                    transition="slide-x-transition"
                    max-width="50vw"
                    max-height="80vh"
                    offset-x
                    right
                    nudge-top="12"
                    open-on-hover
                    open-delay="250"
                    close-delay="250"
                    :close-on-content-click="false"
                    class="selection-control"
                  >
                    <template v-slot:activator="{ on, attrs, value }">
                      <v-btn
                        icon
                        :color="value ? 'primary' : 'grey'"
                        v-on="on"
                        v-bind="attrs"
                        @click.stop
                      >
                        <v-icon 
                          dense
                        >
                          {{ icons.plan }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <campaign-outline
                      :campaign="campaigns[item.value]"
                      :loading="!status[item.value].updated"
                      :cities="cities"
                      :admin="admin"
                      max-height="80vh"
                      class="plan"
                    />
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>

          <!-- <v-divider 
            v-if="listByStatus.on.length>0"
          /> -->
          <!-- <v-subheader 
            v-if="listByStatus.off.length>0"
            class="off text-overline grey--text pt-2"
          >
            Fora do ar
          </v-subheader> -->
          <v-list-item-group
            color="primary"
            class="off"
          >
            <template v-for="item in listByStatus.off">
              <v-list-item
                :key="item.value"
                @click="onScopeUpdate(item.value)"
                class="pr-2 py-1"
              >
                <v-list-item-content>
                  <v-list-item-title class="small">
                    {{ item.text }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          v-if="(admin&&!item.visible)"
                          small
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                        >
                          {{ icons.invisible }}
                        </v-icon>
                      </template>
                      <span>Invisível</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          v-if="(admin&&item.paid!=2)"
                          small
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                        >
                          {{ mode[item.paid].icon }}
                        </v-icon>
                      </template>
                      <span>{{ mode[item.paid].text }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <v-menu
                    transition="slide-x-transition"
                    max-width="50vw"
                    max-height="80vh"
                    offset-x
                    right
                    nudge-top="12"
                    open-on-hover
                    open-delay="250"
                    close-delay="250"
                    :close-on-content-click="false"
                    class="selection-control"
                  >
                    <template v-slot:activator="{ on, attrs, value }">
                      <v-btn
                        icon
                        :color="value ? 'primary' : 'grey'"
                        v-on="on"
                        v-bind="attrs"
                        @click.stop
                      >
                        <v-icon 
                          dense
                        >
                          {{ icons.plan }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <campaign-outline
                      :campaign="campaigns[item.value]"
                      :loading="!status[item.value].updated"
                      :cities="cities"
                      :admin="admin"
                      max-height="80vh"
                      class="plan"
                    />
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      bottom
      height="2"
      color="primary"
      class="loading faded"
    />
  </div>
</template>

<style>

  .scope-btn, .campaign-filter {
    position: relative;
  }

  .faded {
    opacity: .6;
  }
  .faded:hover {
    opacity: 1;
  }
  .campaign-filter .scope-btn, .campaign-filter .scope-btn .v-btn__content {
    max-width: 100% !important;
  }

  .campaign-list .v-input__prepend-inner {
    padding-right: 8px !important;
  }

  .campaign-list .label {
    font-size: 10px !important;
    font-weight: 500 !important;
    letter-spacing: 0.125em;
  }

  .campaign-list .small {
    font-size: .9375em !important;
  }
  .campaign-list .selected.small {
    font-size: .8em !important;
  }

  .campaign-list .campaign-selection {
    border-left: 4px solid var(--primary);
  }
  .campaign-list .list .on {
    border-left: 4px solid var(--success);
  }
  .campaign-list .list .off {
    border-left: 4px solid var(--light-border);
  }

  .campaign-list .campaign-selection {
    position: relative;
    background: #f4f7ff;
  }

  .campaign-list .scope-btn, .campaign-filter .campaign-selection .child-item {
    z-index: 2;
  }

  .campaign-list .campaign-selection .path {
    position: absolute;
    border-width: 1px;
    border-color: var(--mobees-black);
    opacity: .25;
    left: 1.675rem;
    margin-top: -.425rem;
    height: calc(100% - 1.875rem);
    min-height: 0;
    z-index: 1;
  }

  .dashed {
    border-style: dashed;
  }
  .dotted {
    border-style: dotted;
  }
  
</style>

<script>
  // Icons
  import { mdiImageMultiple, mdiMagnify, mdiMenuDown, mdiEyeOffOutline, mdiPlayCircleOutline, mdiPauseCircleOutline, mdiCurrencyUsdOff, mdiGiftOutline, mdiSleep, mdiCircleOffOutline, mdiCurrencyUsd, mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiTextBoxOutline  } from '@mdi/js';
  var moment = require('moment');

  export default {
    props: {
      scope: {
        type: Number,
        default: null
      },
      campaigns: {
        type: [Array, Object],
        default: () => {
          return {}
        }
      },
      status: {
        type: Object,
        default: () => {
          return {}
        }
      },
      loading: {
        type: Boolean,
        default: false
      },
      children: {
        type: Object,
        default: () => null
      },
      childrenFilter: {
        type: Array,
        default: () => null
      },
      cities: {
        type: Object,
        default: () => {}
      },
      admin: {
        type: Boolean,
        default: true
      },
    },

    data: () => ({
      icons: {
        filter: mdiImageMultiple,
        menu: mdiMenuDown,
        plan: mdiTextBoxOutline,
        search: mdiMagnify,
        invisible: mdiEyeOffOutline, 
        inactive: mdiPauseCircleOutline,
        active: mdiPlayCircleOutline,
        bonus: mdiGiftOutline,
        probono: mdiCurrencyUsdOff,
        placeholder: mdiSleep,
        undefined: mdiCircleOffOutline,
        checked: mdiCheckboxMarked,
        unchecked: mdiCheckboxBlankOutline,
      },
      input: {
        search: {
          value: '',
          default: '',
          loading: false,
          disabled: false,
          label: 'Buscar campanha...',
          messages: []
        },
        scope: {
          value: null,
          toggle: false,
          loading: false,
          readonly: false,
          all: {
            brand: '–',
            text: 'Carregando...',
            value: null,
            active: true
          }
        },
      },
      mode: {
        2: {
          value: 2,
          text: 'Paga',
          icon: mdiCurrencyUsd
        },
        1: {
          value: 1,
          text: 'Pro bono',
          icon: mdiCurrencyUsdOff
        },
        3: {
          value: 3,
          text: 'Bônus',
          icon: mdiGiftOutline
        },
        0: {
          value: 0,
          text: 'Calhau',
          icon: mdiSleep
        },
        null: {
          value: null,
          text: 'Indefinida',
          disabled: true,
          icon: mdiCircleOffOutline
        }
      },
      groups: {},
    }),

    computed: {
      campaignList () {
        const children = _.flatten(_.map(_.filter(this.campaigns, c => !_.isEmpty(c.children)), d => d.children));
        // console.log(children);
        const campaigns = _.filter(this.campaigns, c => {
          const list = _.indexOf(children, c.id) < 0;
          return list;
        });
        return _.filter(_.map(campaigns, (item) => {
          return {
            active: item.active,
            airing: item.airing,
            visible: item.visible,
            paid: item.paid,
            text: item.title,
            value: item.id,
            period: item.period,
            children: item.children
          };
        }), c => {
          return (!this.admin && c.visible==1) || this.admin;
        });
      },
      campaign () {
        return _.isNil(this.scope) ? null : _.has(this.campaigns, this.scope) ? this.campaigns[this.scope] : null;
      },
      all () {
        return this.input.scope.value==null;
      },
      scopeDisplay () {
        // console.log('display', this.scope);
        const multiple = this.childrenFilter.length>0;
        const label = multiple ? 'Campanhas: ' : 'Campanha: ';
        return label + (_.isNil(this.scope)||_.isNil(this.campaign) ? 'Carregando...' : this.campaign.title + (!_.isNil(this.childrenFilter)&&multiple ? ' (+'+this.childrenFilter.length+')' : ''));
      },
      filteredItems () {
        const k = this.input.search.value;
        const key = (typeof k != 'undefined' && k != null) ? new RegExp(k.normalize('NFD').replace(/\p{Diacritic}/gu, ''), 'gi') : new RegExp('', 'gi');
        const items = _.filter(this.campaignList, (item) => {
          return k != '' ? key.test(item.text.normalize('NFD').replace(/\p{Diacritic}/gu, '')) : true;
        });
        return items;
      },
      listByStatus () {
        const selected = this.scope;
        const campaigns = this.filteredItems;
        const list = _.groupBy(_.orderBy(_.reject(campaigns, ['value', selected]), ['period.start', 'period.end'], ['desc', 'desc']), 'airing');
        return {
          on: _.has(list, 'true') ? list.true : [],
          off: _.has(list, 'false') ? list.false : []
        };
      },
      hasChildren () {
        return _.size(this.children)>0;
      },
      statusSelected () {
        const selected = _.isNil(this.campaign) ? null : this.campaign.id;
        return _.isNil(selected) ? { loading: false, updated: false } : this.status[selected];
      }
    },

    watch: {
      scope: {
        handler (value) {
          // console.log('scope', value);
          // this.input.scope.value = value;
          this.processChildren();
        }
      },
      campaigns: {
        immediate: true,
        deep: true,
        handler (value) {
          this.processChildren();
        }
      },
      childrenFilter: {
        // immediate: true,
        handler (v) {
          this.processChildren();
        },
      }
    },

    methods: {
      processChildren () {
        const children = !_.isNil(this.scope) && !_.isNil(this.campaign) ? this.campaign.children : [];
        this.groups = {};
        _.each(children, c => {
          this.$set(this.groups, c, _.indexOf(this.childrenFilter, c) >= 0);
        });
        // console.log('groups:', this.groups);
      },

      onScopeUpdate (value) {
        value = _.has(this.campaigns, value) ? value : null;
        setTimeout($ => {
          $.input.scope.toggle = false;
        }, 500, this)
        // this.input.scope.value = value;
        // console.log(value);
        this.emitFilterUpdate(value);
      },

      emitFilterUpdate (scope) {
        this.$emit('updated', scope);
      },

      selectChild (b, id) {
        console.log(b, id);
        this.$set(this.groups, id, b);
        this.updateChildren();
      },

      updateChildren () {
        this.$emit('update-children', _.map(_.keys(_.pickBy(this.groups, s => s==true)), id => parseInt(id)));
      },
    },

    components: {
      CampaignOutline: () => import('@/components/CampaignOutline'),
    }
  }
</script>